import React, { useRef } from "react";
import "./Profile.css"
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { matchPath } from "react-router-dom";


const Profile = () => {
  const location = useLocation();
  const boxRef = useRef();

  const matchRoute = () => {
    return matchPath({ path: "" }, location.pathname);
  };

  return (
    <NavLink
      to={`/`}
      className={`px-2 ${
        matchRoute() ? " cursor-default " : " cursor-pointer"
      } `}
    >
      <div className="flex flex-row gap-3 px-3 items-center">
        <img
          ref={boxRef}
          className="animated-border-radius object-cover"
          style={{
            borderRadius: '47% 53% 46% 54% / 51% 45% 55% 49%',
            width: '44px',
            height: '44px', /* Maintain aspect ratio */
            objectFit: 'cover'
          }}
          src="./assert/profileImg.png"
        />
        <div className="flex flex-col items-start gap-0">
          <p className=" font-semibold text-sm text-richblack-5">Anand Raj</p>
          <p className=" text-sm text-richblack-500"> Web Developer</p>
        </div>
      </div>
    </NavLink>
  );
};

export default Profile;
