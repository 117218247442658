import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toast';
import Dashboard from './components/Dashboard';
import Home from './components/Home';
import Projects from './components/Projects';
import ContactPage from './components/ContactPage';
import ProjectCard from './components/common/ProjectCard';
import ExperiencePage from './components/ExperiencePage';
import MouseTail from './MouseTail';

function App() {
  useEffect(() => {
    if (window.particlesJS) {
      window.particlesJS('particles-js', {
        particles: {
          number: {
            value: 300,
            density: {
              enable: true,
              value_area: 1200
            }
          },
          color: {
            value: "#c5c6d0"
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#373737"
            },
            polygon: {
              nb_sides: 3
            },
            image: {
              src: "img/github.svg",
              width: 100,
              height: 100
            }
          },
          opacity: {
            value: 1,
            random: true,
            anim: {
              enable: true,
              speed: 1,
              opacity_min: 0.1,
              sync: true
            }
          },
          size: {
            value: 2,
            random: true,
            anim: {
              enable: true,
              speed: 5,
              size_min: 0.1,
              sync: false
            }
          },
          line_linked: {
            enable: false,
            distance: 300,
            color: "#ffffff",
            opacity: 0.4,
            width: 2
          },
          move: {
            enable: true,
            speed: 1,
            direction: "none",
            random: true,
            straight: false,
            out_mode: "out",
            bounce: false,
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200
            }
          }
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: true,
              mode: "repulse"
            },
            onclick: {
              enable: true,
              mode: "push"
            },
            resize: true
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 1
              }
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 0.8,
              speed: 1
            },
            repulse: {
              distance: 200,
              duration: 0.5
            },
            push: {
              particles_nb: 5
            },
            remove: {
              particles_nb: 3
            }
          }
        },
        retina_detect: true
      });
    }
  }, []);

  return (
    <div className="w-screen min-h-screen bg-richblue-900 flex font-inter">
      <div id="particles-js" className="fixed top-0 left-0 w-full h-full z-0" />
      <MouseTail />
      <Routes>
        <Route path="/" element={<Dashboard />}>
          <Route index element={<Home />} />
          <Route path="/work" element={<Projects />} />
          <Route path="/work/:id" element={<ProjectCard />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/experience" element={<ExperiencePage />} />
        </Route>
      </Routes>
      <ToastContainer delay={2000} position="bottom-right" />
    </div>
  );
}

export default App;
