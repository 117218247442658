import React, { useEffect, useRef } from 'react';

const MouseTail = () => {
    const coords = { x: 0, y: 0 };
    const circlesRef = useRef([]);

    const colors = [
        "#ffffff", "#fdfdfd", "#fbfbfb", "#f8f9fa", "#f6f6f6",
        "#f4f4f4", "#f2f2f2", "#f0f0f0", "#ededee", "#ebebeb",
        "#e9e9e9", "#e7e7e7", "#e5e5e5", "#e2e3e4", "#e0e0e0",
        "#dedede", "#dbdcdd", "#d9d9da", "#d7d7d7", "#d5d5d5",
        "#d2d3d4", "#d0d0d0", "#cececf", "#cbcccb", "#c9c9c9",
        "#c7c7c8", "#c5c5c5", "#c2c3c4", "#bfbfc0", "#bdbdbd",
        "#babbba", "#6c757d"
    ];
    
    useEffect(() => {
        const circles = circlesRef.current;
        circles.forEach((circle, index) => {
            circle.x = 0;
            circle.y = 0;

            circle.style.backgroundColor = colors[index % colors.length];
            
        });

        const handleMouseMove = (e) => {
            coords.x = e.clientX;
            coords.y = e.clientY;
        };

        window.addEventListener('mousemove', handleMouseMove);

        const animateCircles = () => {
            let x = coords.x;
            let y = coords.y;

            circles.forEach((circle, index) => {
                const nextCircle = circles[index + 1] || circles[0];
                
                x += (nextCircle.x - x) * 0.275; // Lowering the interpolation value for smoother movement
                y += (nextCircle.y - y) * 0.275;

                circle.style.left = `${x - 12 + (index/10)}px`;
                circle.style.top = `${y - 12 + (index/10)}px`;

                // Smoother scaling effect
                circle.style.transform = `scale(${(circles.length - index) / circles.length})`;

                circle.x = x;
                circle.y = y;

            });

            requestAnimationFrame(animateCircles);
        };

        animateCircles();

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, [coords]);

    return (
        <div>
            {[...Array(32)].map((_, i) => (
                <div
                    key={i}
                    className="Mouse-circle absolute w-6 h-6 rounded-full pointer-events-none transition-all ease-out"
                    ref={(el) => circlesRef.current[i] = el}
                    style={{ zIndex: 0 }} // Ensures that the circles are on top
                />
            ))}
        </div>
    );
};

export default MouseTail;
