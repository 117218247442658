export const sidebarLinks = [
    {
        id: 1,
        name: "Work",
        path: "/work",
        icon: "VscAccount",
    },
    {
        id: 2,
        name: "Experience",
        path: "/experience",
        icon: "VscAccount",
    },
    {
        id: 3,
        name: "Contact",
        path: "/contact",
        icon: "VscAccount",
    },

]